* {
    box-sizing: border-box;
}

html,
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0px;
    background-color: #f2f2f2;
    height: 100vh;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-items: center;
}

main {
    flex: 1;
    padding: 10px;
    padding-top: 0px;
    overflow-y: auto;
}

header,
footer {
    background-color: aqua;
    width: 100%;
}

a {
    color: inherit;
    text-decoration: none;
    outline: none;
}

button {
    padding: 5px 10px;
    border-radius: 20px;
}

p.center {
    text-align: center;
}

/*///////////////////////////////////////////////////////////////////////////*/

header {
    position: relative;
    display: flex;
    justify-content: space-between;
}

header>div {
    /* padding: 10px; */
}

header a {
    text-decoration: none;
}

.top-menu-bar {
    display: flex;
    /* padding-left: 5px; */
}

.top-menu {
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.top-menu.active {
    background-color: red;
}

.top-menu.not-active {
    background-color: green;
}

#username {
    background-color: red;
    cursor: pointer;
    padding: 10px;
}

.dropdown-menu {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-menu a:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

#message,
.message {
    font-weight: bold;
}

/*///////////////////////////////////////////////////////////////////////////*/

footer {
    background-color: aqua;
    padding: 10px;
}

footer>div {}

.error {
    background-color: red;
}

/*///////////////////////////////////////////////////////////////////////////*/

.inbound-connections {
    padding: 5px;
    background-color: #fbb;
    border-radius: 10px;
}

.outbound-connections {
    padding: 5px;
    background-color: #bbf;
    border-radius: 10px;
}

.connections {
    padding: 5px;
    background-color: #bfb;
    border-radius: 10px;
}

.inbound-connections:empty,
.outbound-connections:empty,
.connections:empty {
    display: none;
}


/*///////////////////////////////////////////////////////////////////////////*/

.user-panel {
    user-select: text;
    padding: 15px;
    border: 1px solid grey;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #f2f2f2;
    display: grid;
    gap: 10px;
}

.user-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user-panel:first-child {
    margin-top: 0px;
}

.user-panel:not(:first-child) {
    margin-top: 25px;
}

.user-panel:last-child {
    margin-bottom: 5px;
}

.user-panel:not(:last-child) {
    margin-bottom: 25px;
}

.connection-note {
    font-style: italic;
}

.button-bar {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 5px;
}

.button-bar>div {
    flex-grow: 1
}

button.set {
    background-color: black;
    color: white;
}

button.break {
    border: 1px solid red;
}

/*///////////////////////////////////////////////////////////////////////////*/

.canvas-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
}

canvas {
    max-width: 100%;
    max-height: 100%;
}

.qr-link {
    margin-top: 10px;
    font-size: 14px;
}