* {
  box-sizing: border-box;
}

html, body {
  user-select: none;
  background-color: #f2f2f2;
  flex-direction: column;
  justify-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
}

main {
  flex: 1;
  padding: 0 10px 10px;
  overflow-y: auto;
}

header, footer {
  background-color: #0ff;
  width: 100%;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

button {
  border-radius: 20px;
  padding: 5px 10px;
}

p.center {
  text-align: center;
}

header {
  justify-content: space-between;
  display: flex;
  position: relative;
}

header a {
  text-decoration: none;
}

.top-menu-bar {
  display: flex;
}

.top-menu {
  padding: 10px 5px;
}

.top-menu.active {
  background-color: red;
}

.top-menu.not-active {
  background-color: green;
}

#username {
  cursor: pointer;
  background-color: red;
  padding: 10px;
}

.dropdown-menu {
  z-index: 1;
  background-color: #f9f9f9;
  min-width: 120px;
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0 8px 16px #0003;
}

.dropdown-menu a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

#message, .message {
  font-weight: bold;
}

footer {
  background-color: #0ff;
  padding: 10px;
}

.error {
  background-color: red;
}

.inbound-connections {
  background-color: #fbb;
  border-radius: 10px;
  padding: 5px;
}

.outbound-connections {
  background-color: #bbf;
  border-radius: 10px;
  padding: 5px;
}

.connections {
  background-color: #bfb;
  border-radius: 10px;
  padding: 5px;
}

.inbound-connections:empty, .outbound-connections:empty, .connections:empty {
  display: none;
}

.user-panel {
  user-select: text;
  background-color: #f2f2f2;
  border: 1px solid gray;
  border-radius: 10px;
  gap: 10px;
  padding: 15px;
  display: grid;
  box-shadow: 0 0 10px #0000004d;
}

.user-top-bar {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.user-panel:first-child {
  margin-top: 0;
}

.user-panel:not(:first-child) {
  margin-top: 25px;
}

.user-panel:last-child {
  margin-bottom: 5px;
}

.user-panel:not(:last-child) {
  margin-bottom: 25px;
}

.connection-note {
  font-style: italic;
}

.button-bar {
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
  display: flex;
}

.button-bar > div {
  flex-grow: 1;
}

button.set {
  color: #fff;
  background-color: #000;
}

button.break {
  border: 1px solid red;
}

.canvas-container {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

canvas {
  max-width: 100%;
  max-height: 100%;
}

.qr-link {
  margin-top: 10px;
  font-size: 14px;
}
/*# sourceMappingURL=400.79175bd6.css.map */
